<template>
  <v-app-bar app height="100">
    <v-app-bar-nav-icon
      @click="drawer()"
      class="primary--text"
    ></v-app-bar-nav-icon>
    <v-container class="py-0 fill-height" fluid>
      <div
        class="mr-3"
        v-if="
          $vuetify.breakpoint.name == 'lg' ||
          $vuetify.breakpoint.name == 'xl' ||
          $vuetify.breakpoint.name == 'md'
        "
      >
        <v-img src="@/assets/gambar/logo-new.png" max-width="200"></v-img>
      </div>

      <div class="mr-3" v-else>
        <v-img src="@/assets/gambar/logo-new.png" max-width="150"></v-img>
      </div>

      <div
        class="mr-10 primary--text"
        v-if="
          $vuetify.breakpoint.name == 'lg' ||
          $vuetify.breakpoint.name == 'xl' ||
          $vuetify.breakpoint.name == 'md'
        "
      >
        <h1>- BKPP POHUWATO</h1>
      </div>
      <div class="mr-10 primary--text" v-else></div>

      <v-spacer></v-spacer>
      <v-menu bottom min-width="200px" rounded offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            <v-avatar size="50">
              <v-icon size="50" color="primary">mdi-account-circle</v-icon>
              <!-- <v-img :src="foto"></v-img> -->
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar size="70">
                <v-icon size="80">mdi-account-circle</v-icon>
                <!-- <v-img :src="foto"></v-img> -->
              </v-avatar>
              <div class="mt-3 px-2">
                <h4>{{ namaPegawai }}</h4>
                <p class="caption mt-1">
                  {{ nipBaru }}
                </p>
              </div>
              <v-divider></v-divider>
              <v-btn depressed text block @click="edit()"> Edit Account </v-btn>
              <v-divider></v-divider>
              <v-btn depressed text @click="logout()" block> Sigin Out </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-container>
  </v-app-bar>
</template>

<script>
import { version } from "../../package";

import Cookie from "@/helper/cookie.js";
import Auth from '@/helper/auth.js'

import drawerM from "@/store/menuM";

export default {
  data: () => ({
    appVersion: version,

    token: Cookie.get("token"),
    session: "",

    nipBaru: '',
    namaPegawai: '',
    foto: '',
  }),

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.getData();
  },

  methods: {
    drawer() {
      drawerM.commit("toggle", !drawerM.state.drawer);
    },

    getData() {
      const url =
        process.env.VUE_APP_ASN + "datautama/nip/" + this.session.nipBaru;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.nipBaru = response.data.mapData.DataUtama.nipBaru;
          this.namaPegawai = response.data.mapData.DataUtama.nama;
          // this.foto = response.data.mapData.DataUtama.foto;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    logout() {
      Cookie.delete();
      localStorage.clear();
      window.location.href = process.env.VUE_APP_LOGIN;
    },

    edit() {
      window.open("https://account.idasn.pohuwatokab.go.id", "_blank");
    },
  },
};
</script>