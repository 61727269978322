<template>
  <v-app id="inspire">
    <CMenuM />
    <CHeader />

    <v-main
      class="grey lighten-3 mt-5 px-10 pb-10"
      v-if="
        $vuetify.breakpoint.name == 'lg' ||
        $vuetify.breakpoint.name == 'xl' ||
        $vuetify.breakpoint.name == 'md'
      "
    >
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>

    <v-main class="grey lighten-3 mt-5 pb-10" v-else>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>

    <CFooter />
  </v-app>
</template>

<script>
import CHeader from "@/layout/header";
import CMenuM from "@/layout/menuM";
import CFooter from "@/layout/footer";
// import CMenu from "@/layout/menu";
// import CMenuPNS from "@/layout/menuPNS";
// import CSlider from "@/components/pns/profil/slider";

export default {
  components: {
    CHeader,
    CMenuM,
    CFooter,
    // CMenu,
    // CMenuPNS,
    // CSlider,
  },

  data: () => ({}),

  computed: {
    firstSegment: function () {
      const str = this.$route.path;
      const firstSegment = str.split("/")[1];

      return firstSegment;
    },

    secondSegment: function () {
      const str = this.$route.path;
      const secondSegment = str.split("/")[2];

      return secondSegment;
    },
  },

  mounted() {
    if (this.$route.path === "/") {
      this.$router.push("/dashboard").catch(() => {});
    }
  },
};
</script>