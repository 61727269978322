import Vue from 'vue'
import Cookie from '@/helper/cookie.js'

import VueRouter from 'vue-router'
import Main from '../layout/main.vue'

Vue.use(VueRouter)

const routes = [{
    // catch all 404 - define at the very end
    path: "*",
    name: 'notfound',
    component: () => import( /* webpackChunkName: "notfound" */ '../layout/notfound.vue')
  },

  {
    path: '/auth',
    component: () => import(
      /* webpackChunkName: "auth" */
      /* webpackPrefetch: true */
      /* webpackPreload: true */
      "../views/Auth"
    ),
    meta: {
      title: 'idasn'
    },
  },

  {
    path: '/',
    name: 'main',
    component: Main,
    meta: {
      title: 'idasn',
      requiresAuth: true
    },
    children: [{
        path: '/dashboard',
        name: 'dashboard',
        component: () => import( /* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
      },

      // UNIT KERJA
      {
        path: '/unitkerja',
        name: 'unitkerja',
        component: () => import( /* webpackChunkName: "unitkerja" */ '../components/dashboard/unitkerja/main.vue')
      },
      {
        path: '/unitkerja/kategori/:id',
        name: 'unitkerja-kategori',
        component: () => import( /* webpackChunkName: "unitkerja_kategori" */ '../components/dashboard/unitkerja/kategori.vue')
      },
      {
        path: '/unitkerja/detail/:id',
        name: 'unitkerja-detail',
        component: () => import( /* webpackChunkName: "unitkerja_detail" */ '../components/dashboard/unitkerja/detail.vue')
      },
      {
        path: '/unitkerja/add',
        name: 'unitkerja-add',
        component: () => import( /* webpackChunkName: "unitkerja_add" */ '../components/dashboard/unitkerja/add.vue')
      },

      // PNS
      {
        path: '/pns',
        name: 'pns',
        component: () => import( /* webpackChunkName: "pns" */ '../views/PNS.vue'),
        children: [{
          path: '/pns/:id',
          name: 'pns_data',
          component: () => import( /* webpackChunkName: "pns_data" */ '../components/pns/dashboard/dashboard.vue')
        }, ]
      },

      // {
      //   path: '/pns/dashboard',
      //   name: 'pns_dashboard',
      //   component: () => import( /* webpackChunkName: "pns_dashboard" */ '../components/pns/dashboard/dashboard.vue')
      // },
      // {
      //   path: '/pns/profil',
      //   name: 'pns_profil',
      //   component: () => import( /* webpackChunkName: "pns_profil" */ '../components/pns/profil/profil.vue')
      // },
      // {
      //   path: '/pns/profil/datautama',
      //   name: 'pns_datautama',
      //   component: () => import( /* webpackChunkName: "pns_datautama" */ '../components/pns/profil/datautama/main.vue')
      // },
      // {
      //   path: '/pns/profil/rwpkg',
      //   name: 'pns_rwpkg',
      //   component: () => import( /* webpackChunkName: "pns_rwpkg" */ '../components/pns/profil/rwpkg/main.vue')
      // },

      // PPPK
      {
        path: '/pppk',
        name: 'pppk',
        component: () => import( /* webpackChunkName: "pppk" */ '../views/PPPK.vue')
      },

      // NONASN
      {
        path: '/nonasn',
        name: 'nonasn',
        component: () => import( /* webpackChunkName: "nonasn" */ '../views/NonASN.vue')
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  let myCookie = Cookie.get('myCookie');
  let token = Cookie.get('token');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!myCookie || !token) {
      next({
        path: '/auth',
        params: {
          nextUrl: to.fullPath
        }
      })
    } else {
      const session = JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      let arr = session.authorities
      if (to.matched.some(record => record.meta.early_check)) {
        if (arr.length > 0) {
          next()
        } else {
          next({
            name: 'home'
          })
        }
      } else if (to.matched.some(record => record.meta.READ_ASN_ALL_OPD)) {
        if (arr.some((item) => item === 'READ_ASN_ALL_OPD')) {
          next()
        } else {
          next({
            name: 'home'
          })
        }
      } else if (to.matched.some(record => record.meta.CRUD_ADMINISTRATOR)) {
        if (arr.some((item) => item === 'CRUD_ADMINISTRATOR')) {
          next()
        } else {
          next({
            name: 'home'
          })
        }
      } else {
        next()
      }

    }
  } else {
    next()
  }
})

export default router