import axios from "axios";
import Cookie from '@/helper/cookie.js'

export default {
    cek: async (token) => {
        const url = process.env.VUE_APP_AUTH + 'cek'
        const response = await axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        })
        return response.data.mapData
    },

    logout: (token) => {
        console.log(token);
        Cookie.delete()
        localStorage.clear()
    }
}