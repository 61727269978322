var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{attrs:{"app":"","height":"100"}},[_c('v-app-bar-nav-icon',{staticClass:"primary--text",on:{"click":function($event){return _vm.drawer()}}}),_c('v-container',{staticClass:"py-0 fill-height",attrs:{"fluid":""}},[(
        _vm.$vuetify.breakpoint.name == 'lg' ||
        _vm.$vuetify.breakpoint.name == 'xl' ||
        _vm.$vuetify.breakpoint.name == 'md'
      )?_c('div',{staticClass:"mr-3"},[_c('v-img',{attrs:{"src":require("@/assets/gambar/logo-new.png"),"max-width":"200"}})],1):_c('div',{staticClass:"mr-3"},[_c('v-img',{attrs:{"src":require("@/assets/gambar/logo-new.png"),"max-width":"150"}})],1),(
        _vm.$vuetify.breakpoint.name == 'lg' ||
        _vm.$vuetify.breakpoint.name == 'xl' ||
        _vm.$vuetify.breakpoint.name == 'md'
      )?_c('div',{staticClass:"mr-10 primary--text"},[_c('h1',[_vm._v("- BKPP POHUWATO")])]):_c('div',{staticClass:"mr-10 primary--text"}),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","min-width":"200px","rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":"","x-large":""}},on),[_c('v-avatar',{attrs:{"size":"50"}},[_c('v-icon',{attrs:{"size":"50","color":"primary"}},[_vm._v("mdi-account-circle")])],1)],1)]}}])},[_c('v-card',[_c('v-list-item-content',{staticClass:"justify-center"},[_c('div',{staticClass:"mx-auto text-center"},[_c('v-avatar',{attrs:{"size":"70"}},[_c('v-icon',{attrs:{"size":"80"}},[_vm._v("mdi-account-circle")])],1),_c('div',{staticClass:"mt-3 px-2"},[_c('h4',[_vm._v(_vm._s(_vm.namaPegawai))]),_c('p',{staticClass:"caption mt-1"},[_vm._v(" "+_vm._s(_vm.nipBaru)+" ")])]),_c('v-divider'),_c('v-btn',{attrs:{"depressed":"","text":"","block":""},on:{"click":function($event){return _vm.edit()}}},[_vm._v(" Edit Account ")]),_c('v-divider'),_c('v-btn',{attrs:{"depressed":"","text":"","block":""},on:{"click":function($event){return _vm.logout()}}},[_vm._v(" Sigin Out ")])],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }