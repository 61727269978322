<template>
  <v-navigation-drawer v-model="drawer" temporary app>
    <v-list-item @click="close()">
      <v-list-item-icon>
        <v-icon>mdi-close</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Tutup </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list color="transparent" dense nav shaped>
      <v-list-item to="/dashboard" link color="primary">
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Dashboard </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-list-item to="/unitkerja" link color="primary">
        <v-list-item-icon>
          <v-icon>mdi-office-building</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Unit Kerja </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/jabatan" link disabled color="primary">
        <v-list-item-icon>
          <v-icon>mdi-tie</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Jabatan </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/kompetensi" link disabled color="primary">
        <v-list-item-icon>
          <v-icon>mdi-bag-checked</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Kompetensi </v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <v-divider class="my-2"></v-divider>

      <v-list-item class="mt-2" to="/pns" link color="primary">
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Profil Pegawai </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-list-item to="/pns/profil" link color="primary">
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Profil Pegawai </v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- <v-list-item to="/pns/aparatur" link color="primary">
        <v-list-item-icon>
          <v-icon>mdi-account-supervisor</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Aparatur </v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import drawerM from "@/store/menuM";

export default {
  computed: {
    drawer: {
      get() {
        return drawerM.state.drawer;
      },

      set(value) {
        drawerM.commit("toggle", value);
      },
    },
  },

  data: () => ({}),

  methods: {
    close() {
      drawerM.commit("toggle", !drawerM.state.drawer);
    },
  },
};
</script>